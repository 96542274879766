import { useState } from "react";

const { name, questions, checkoutLink } = require("./config/product");

function App() {
  const [currentQuestionId, setCurrentQuestionId] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(questions[0]);

  function nextQuestion() {
    if (currentQuestionId < questions.length - 1) {
      setCurrentQuestionId(currentQuestionId + 1);
      setCurrentQuestion(questions[currentQuestionId + 1]);
    } else {
      window.location.replace(checkoutLink);
    }
  }

  return (
    <div className="App w-full h-screen flex flex-col items-center justify-center p-2">
      <div className="w-[100%] lg:w-[40%] min-h-[80%] p-10 bg-zinc-800 bg-opacity-20 backdrop-blur-sm rounded-lg shadow-md border-[1px] border-zinc-500 flex flex-col items-center justify-between gap-10">
        <div className="flex flex-col items-center justify-center gap-2">
          <p className="text-zinc-300">
            Pergunta {currentQuestionId + 1} de {questions.length}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img src={currentQuestion.img} width={"80%"} alt="Question" />
          <div className="flex flex-col items-center justify-center gap-1 py-5">
            <h1 className="text-zinc-100 text-3xl font-semibold">
              {currentQuestion.question}
            </h1>
            <h2 className="text-sm text-zinc-300">
              {currentQuestion.subheadline}
            </h2>
          </div>
          <div className="flex justify-between gap-2 w-[100%] flex-wrap">
            {currentQuestion.type == "button" &&
              currentQuestion.buttons.map((button, index) => (
                <button
                  key={index}
                  className="bg-orange-500 w-full text-zinc-100 rounded-lg px-4 py-2 font-medium"
                  onClick={() => nextQuestion()}
                >
                  {button}
                </button>
              ))}
          </div>
        </div>
        <div>
          <p className="text-zinc-300 text-sm">
            Copyright &copy; {new Date().getFullYear()} {name}
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
