export const name = "EasyTreino";
export const checkoutLink = "https://www.easytreino.com.br/checkout";
export const questions = [
  {
    question: "João é gay?",
    subheadline: "Sim ou Não",
    type: "button",
    img: "https://via.placeholder.com/1080x1080",
    buttons: ["Sim"],
  },
  {
    question: "Teste2",
    subheadline: "Sim ou Não",
    type: "button",
    img: "https://via.placeholder.com/1080x1080",
    buttons: ["Sim", "Não"],
  },
];
